import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GetUserInfo from './../GetUserInfo';
import SearchComponent from './SearchComponent';
import { useAuth } from './AuthContext';
import testAuthData from './AuthContext';


const ItemsPerPage = 10; // Number of items to fetch per page

const GetTextRegistration = (registrations, eventEndDate, gigStatus) => {
   return "Open";// For testing with gig proposal

  if (registrations == null || typeof registrations !== 'number') {
    console.log('Invalid registration data or null, running an odd patch that works');
    return registrations.RegistrationText;
  }

  if (gigStatus === "Canceled") {
    return "Canceled";
  }

  console.log('Get Text Registrations', registrations);

  const currentDate = moment(); // Get the current date

  if (moment(eventEndDate).isBefore(currentDate, 'day')) {
    return "Expired";
  } else if (registrations === 0) {
    return "Open";
  } else {
    return "Closed";
  }
};

const GetRegistrationBackgroundColor = (event, EventEndDate, GigStatus) => {
  if (GigStatus === "Canceled") {
    return "gray";
  }
  // If the event end date is in the past, return 'gray'
  const currentDate = moment(); // Get the current date
  if (moment(EventEndDate).isBefore(currentDate, 'day')) {
    return 'gray';
  } else {
    if (event.Gig_Confirmed_Registrations === 0) {
      return 'green';
    } else if (event.Gig_Confirmed_Registrations > 0) {
      return 'red';
    }
  }
};

const EventListComponent = ({Role,AuthData}) => {
   const [filteredEvents, setFilteredEvents] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [venueLogo, setVenueLogo] = useState(null);
  const [venueName, setVenueName] = useState('');
  const [venueDescription, setVenueDescription] = useState('');
  const [eventProposed, setEventProposed] = useState('');
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [proposalPrice, setProposalPrice] = useState('');
  const [proposalMessage, setProposalMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [selectedDistance, setSelectedDistance] = useState('');
  
  const ItemsPerPage = 10; // Number of items to fetch per page
  
  
  //setRole('subscriber');




  const loadItems = async (page) => {
    try {
      setLoadingPage(true); // Set loadingPage to true to indicate start of loading

      // Construct URL with page and limit parameters
      const url = `http://34.232.96.254/GetGigsForBand.php?page=${page}&limit=${ItemsPerPage}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      if (jsonData.gigs && Array.isArray(jsonData.gigs)) {
        const newItems = jsonData.gigs.map((event) => ({
          id: event.Gig_id,
          name: event.Gig_Title,
          time: moment(event.Gigs_start_time, 'HH:mm:ss').format('h:mm A'),
          image: event.Gig_Image, // Ensure correct base64 data URL format
          description: event.Gig_Description,
          VenueID: event.VenueID,
          metaData: event.Gig_Data,
          StartDate: moment(event.Gig_start_date).format('YYYY-MM-DD'),
          EndDate: moment(event.Gig_end_date).format('YYYY-MM-DD'),
          StartTime: moment(event.Gigs_start_time, "HH:mm:ss"),
          EndTime: moment(event.Gigs_end_time, "HH:mm:ss"),
          Location: event.Geolocation_formatted_address,
          Address: event.Gig_Location_Address,
          RegistrationStatus: parseInt(event.Gig_Confirmed_Registrations || 0, 10),
          RegistrationText: GetTextRegistration(parseInt(event.Gig_Confirmed_Registrations || 0, 10), moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
          RegistrationBackgroundColor: GetRegistrationBackgroundColor(event, moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
        }));
        
        // Sort events from newest to oldest based on startDate
        newItems.sort((a, b) => moment(b.StartDate).diff(moment(a.StartDate)));

        setFilteredEvents((prevEvents) => {
          const uniqueEvents = [...prevEvents, ...newItems].reduce((acc, event) => {
            if (!acc.find((e) => e.id === event.id)) {
              acc.push(event);
            }
            return acc;
          }, []);
          return uniqueEvents;
        });
      } else {
        console.error('Unexpected response structure:', jsonData);
        alert('Error: Unexpected response structure from server.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert(`Error: Failed to fetch data. ${error.message}`);
    } finally {
      setLoadingPage(false); // Set loadingPage to false after fetching data
    }
  };

  useEffect(() => {
    

    // Load items when the component mounts and whenever page state changes
    loadItems(page);
  }, [page]); // Dependency array ensures useEffect runs when page changes

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleViewDetails = async (event) => {
    try {
      console.log("VenueID: " + event.VenueID); // Check VenueID before fetch
      const response = await fetch(`http://34.232.96.254/GetVenueInformationByID.php?Venue_ID=${event.VenueID}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const venueData = await response.json();
      console.log("Venue Data:", venueData); // Log fetched venue data for debugging
      
      // Check if Venue_Logo is present and valid base64 encoded string
      if (venueData.Venue_Logo&&venueData.Venue_name&&venueData.Venue_Description) {
        setVenueLogo(`${venueData.Venue_Logo}`); // Ensure correct base64 data URL format
        setVenueName(`${venueData.Venue_name}`);
        setVenueDescription(`${venueData.Venue_Description}`);
      } else {
        console.warn('Venue_Logo not found in venueData:', venueData);
      }
      incrementGigViews(event.id)
      setEventDetails(event);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching venue details:', error);
      alert(`Error: Failed to fetch venue details. ${error.message}`);
    }
  };

  
  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? '0' + num : num);

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

  const handleProposalSubmit = async (event, user) => {
    try {
      console.log("Gig_id from Send Registration:"+event.id);
        // Get attendee name and email
       // const attendee_name = await GetUserInfo.GetUsernameFromIDForBand(user);
        //const attendee_email = await GetUserInfo.GetBandEmailFromID(user);

        // Get request by name and email
        //const request_by_name = event.name;
        //const request_by_email = await GetUserInfo.GetOrganizerEmailForEvent(event.id);

        const Organizer_ID =  await GetUserInfo.GetOrganizerIDForEvent(event.id);
        const VenueDetails = await GetUserInfo.GetVenueDetailsForEvent(event.id);
        const BandDetails =await GetUserInfo.GetBandDetailsByUserID(user); // band user id

        console.log("Registration DATA:"+registrationData);

        
        // Construct registration data
        const registrationData = {
          Gig_id: event.id, // Include event_id here
          Organizer_id:Organizer_ID,
          Band_id:user,
          Venue:VenueDetails.Venue,
          Venue_id:VenueDetails.VenueID,
          Gig_Title:event.name,
          Musician_first_name:BandDetails.Musician_first_name,
          Musician_last_name:BandDetails.Musician_last_name,
          Proposed_Price:proposalPrice,
          Proposed_Message:proposalMessage,
          Proposal_Status:"Submitted",
          Band_Email:BandDetails.Band_email,
          Gig_Name:event.name,
          Date_Registered:formatDate(new Date()),
          Band_name:BandDetails.Band_name
          
    

        };

        console.log('Registration Data:', registrationData);

        const response = await fetch('http://34.232.96.254/SendProposal.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify(registrationData),
        });

        if (!response.ok) {
            throw new Error('Failed to send registration');
        }

        const result = await response.json();

        if (result.success) {
            console.log('Registration successful. Registration ID:', result.message);

            window.alert("Registration Sent");
            
        } else {
            console.error('Failed to register. Server response:', result.message);
        }

        /*

        if(result.event_id)
        {
          console.log('Registration event id received from php:',result.event_id);
        }

        if(result.meta_data)
        {
          console.log('Registration data received from php:',result.meta_data);
        }

        if(result._attendee_user_id)
        {
          console.log('Registration user id received from php:',result._attendee_user_id);
        }

       */

    
    } catch (error) {
        console.error('Error sending registration:', error.message);
    }
};

function incrementGigViews(gigId) {
  // URL of the PHP script
  const url = `http://34.232.96.254/Viewed.php?Gig_id=${gigId}`;

  // Fetch the URL with a GET request
  fetch(url)
      .then(response => {
          // Check if the response is successful
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          // Return the JSON response
          return response.json();
      })
      .then(data => {
          // Handle the JSON response data
          console.log(data);
          // You can further process the response if needed
      })
      .catch(error => {
          // Handle any errors
          console.error('There was a problem with the fetch operation:', error);
      });
}



  
  
const handleSearch = async () => {

console.log("Search: "+searchQuery);

if(searchQuery==''&&searchLocation=='')
{
  setFilteredEvents([]);
 
  loadItems(1);
   
   // setShowModal(false)
    return;
}

  if (searchLocation.trim() !== '') {
    const isValidFormat = /^[0-9]+\s+[a-zA-Z0-9\s]+,[a-zA-Z\s]+,[a-zA-Z]{2}$/.test(searchLocation.trim());
    if (!isValidFormat) {
      window.alert('Invalid Address Format', 'Please enter the address in the correct format (e.g., "123 Main St, City, State (e.g., MS)")');
      return;
    }
  }
 

  

  try {
    setLoadingPage(true);
   // setIsLoadingIndicator(true);
    let apiUrl = '';

    if (searchLocation === '' && searchQuery !== '') {
      apiUrl = `http://34.232.96.254/GetGigsForBand.php?keyword=${encodeURIComponent(searchQuery)}&limit=${ItemsPerPage}&page=1`;
    } else if (searchLocation !== '' && searchQuery === '') {
      apiUrl = `http://34.232.96.254/GetGigsForBand.php?address=${encodeURIComponent(searchLocation)}&distance=${selectedDistance}&limit=${ItemsPerPage}&page=1`;
    } else if (searchLocation !== '' && searchQuery !== '') {
      apiUrl = `http://34.232.96.254/GetGigsForBand.php?address=${encodeURIComponent(searchLocation)}&keyword=${encodeURIComponent(searchQuery)}&distance=${selectedDistance}&limit=${ItemsPerPage}&page=1`;
    } else {
      apiUrl = `http://34.232.96.254/GetGigsForBand.php?limit=${ItemsPerPage}&page=1`;
    }

    const response = await fetch(apiUrl);
    const jsonData = await response.json();

    if (jsonData.gigs && jsonData.gigs.length === 0) {
      window.alert("No Matches", "There are no gigs matching your search.");
      setFilteredEvents([]);
    } else if (jsonData.gigs && Array.isArray(jsonData.gigs)) {
      const newItems = jsonData.gigs.map((event) => {
        const imageUrl = event.Gig_Image;
        const registrations = parseInt(event.Gig_Confirmed_Registrations || 0, 10);
        const startTime = moment(event.Gigs_start_time, "HH:mm:ss");
        const endTime = moment(event.Gigs_end_time, "HH:mm:ss");

        return {
          id: event.Gig_id,
          name: event.Gig_Title,
          time: moment(event.Gigs_start_time, 'HH:mm:ss').format('h:mm A'),
          image: event.Gig_Image,
          description: event.Gig_Description,
          VenueID: event.VenueID,
          metaData: event.Gig_Data,
          StartDate: moment(event.Gig_start_date).format('YYYY-MM-DD'),
          EndDate: moment(event.Gig_end_date).format('YYYY-MM-DD'),
          StartTime: startTime,
          EndTime: endTime,
          Location: event.Geolocation_formatted_address,
          Address: event.Gig_Location_Address,
          RegistrationStatus: registrations,
          RegistrationText: GetTextRegistration(registrations, moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
          RegistrationBackgroundColor: GetRegistrationBackgroundColor(event, moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
        };
      });
     // setIsLoadingIndicator(false);
     
      setFilteredEvents(newItems);
    } else {
      
    //  setIsLoadingIndicator(false);
      console.error('Unexpected response structure:', jsonData);
      window.alert('Error', 'Unexpected response structure from server.');
    }

    setLoadingPage(false);
  } catch (error) {
    console.error("Error fetching data:", error.message);
    window.alert('No Matches Were Found ... loaded All Gigs Instead');
    setLoadingPage(false);
   
    setFilteredEvents([]);
    loadItems(1);
  }
};



function createImageUrl(base64String) {
  // Check if the base64 string already contains the prefix
  if (base64String.startsWith('data:image/')) {
      return base64String; // No need to modify
  }
  
  // Define known base64 prefix for JPEG images
  const jpegPrefix = 'data:image/jpeg;base64,';

  // Define known base64 prefixes for different image types
  const imageTypes = {
      'iVBORw0KGgo': 'png',  // PNG base64 prefix
      '/9j/4': 'jpeg',      // JPEG base64 prefix
      'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
  };

  // Detect image type
  let imageType = '';
  for (const [prefix, type] of Object.entries(imageTypes)) {
      if (base64String.startsWith(prefix)) {
          imageType = type;
          break;
      }
  }

  // If no known image type is found, assume JPEG
  if (!imageType) {
      imageType = 'jpeg';
  }

  // Return the data URL with the detected or default image type
  return `data:image/${imageType};base64,${base64String}`;
}

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
    <>
    <div style={{
    display: 'flex',           // Enable Flexbox
    alignItems: 'center',     // Center items vertically
    justifyContent: 'flex-start', // Align items to the left
    position: 'relative', 
    top: 0, 
    width: '100%', 
    zIndex: 0, 
    backgroundColor: 'white', 
    padding: '10px',
}}>
      <SearchComponent
        Role={Role}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchLocation={searchLocation}
        setSearchLocation={setSearchLocation}
        selectedDistance={selectedDistance}
        setSelectedDistance={setSelectedDistance}
        onSearch={handleSearch}
      />
      
    </div>
    
    <div style={{
    maxWidth: '920px',
    margin: '0 auto',
   backgroundColor: 'white',
    position: 'absolute', // Use absolute positioning
    top: '100px', // Adjust this value to control vertical position
    left: '50%', // Center it horizontally
    transform: 'translateX(-50%)' // Center it properly
}}>
 

      
      
     
      <div style={{textAlign: 'left',marginBottom:'20px'}}>
	  
        </div>
      {filteredEvents.map((event) => (
        <div key={event.id} style={{ display: 'flex', alignItems: 'stretch', marginBottom: '20px',backgroundColor: '#ffffff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
          <div style={{ flex: '1 1 50%', position: 'relative', maxHeight: '400px', overflow: 'hidden', aspectRatio: '16/9' }}>
            <img
              src={createImageUrl(event.image)}
              alt={event.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
          <div style={{ flex: '1 1 50%', paddingLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px', position: 'relative' }}>
            <h2 style={{ fontSize: '1.5rem', marginBottom: '10px', fontWeight: 'bold' }}>{event.name}</h2>
            <p style={{ fontSize: '1rem', marginBottom: '5px' }}>Start Date: {event.StartDate}</p>
            <p style={{ fontSize: '1rem', marginBottom: '5px' }}>Start Time: {event.StartTime.format('h:mm A')}</p>
            <p style={{ fontSize: '1rem', marginBottom: '5px' }}>End Time: {event.EndTime.format('h:mm A')}</p>
            <p style={{ fontSize: '1rem', marginBottom: '5px' }}>Location: {event.Location}</p>
            <p style={{ fontSize: '1rem', marginBottom: '5px' }}>Address: {event.Address}</p>
           
            <div style={{ position: 'absolute', top: '10px', right: '10px', padding: '5px 10px', backgroundColor: event.RegistrationBackgroundColor, color: '#fff', borderRadius: '4px' }}>
              {event.RegistrationText}
            </div>
            <button 
              style={{ marginTop: '10px', padding: '8px 16px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
              onClick={() => handleViewDetails(event)}
            >
              View Details
            </button>
          </div>
          
        </div>
        
      ))}
      
      {loadingPage && <p style={{ textAlign: 'center', marginTop: '20px', backgroundColor: '#e2e6e9' }}>Loading more items...</p>}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>{eventDetails?.name}</Modal.Title>




        </Modal.Header>
        <Modal.Body>
          {venueLogo && (
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <img
              src={createImageUrl(venueLogo)}
              alt="Venue Logo"
              style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'contain' }}
            />
             <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <h5 >Venue Name: {venueName}</h5>
            <h5>Venue Description: {venueDescription}</h5>
            </div>
          </div>
          )}
          <h5>Start Time: {eventDetails?.StartTime.format('h:mm A')}</h5>
          <h5>End Time: {eventDetails?.EndTime.format('h:mm A')}</h5>
          <h5>Location: {eventDetails?.Location}</h5>
          <h5>Address: {eventDetails?.Address}</h5>
          <p>{eventDetails?.description}</p>
          {eventDetails?.Address && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <iframe
                title="Google Map"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAF2Q8fA6MwI4ggg-uxaSnOk4tCeCZH7Ds&q=${encodeURIComponent(eventDetails.Address)}`}
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
        {Role=='subscriber' &&eventDetails?.RegistrationText=='Open'&& (
        <Button variant="secondary" onClick={() => {setShowModal(false);setShowProposalModal(true)}}>
            Proposal For The Gig
          </Button>
        )}
          <Button variant="secondary" onClick={() => {setShowModal(false);setEventProposed(eventDetails?.id)}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProposalModal} onHide={() => setShowProposalModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>{eventDetails?.name}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <h5>Proposal For The Gig:</h5>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Price:</label>
        <input type="text" value={proposalPrice} onChange={(e) => setProposalPrice(e.target.value)} />
      </div>
      <div style={{ marginBottom: '10px', textAlign: 'left' }}>
        <label>*Optional* Message:</label>
        <textarea
          rows="6" // Increased rows for a larger input area
          value={proposalMessage}
          onChange={(e) => setProposalMessage(e.target.value)}
          style={{ width: '100%', minHeight: '100px', marginTop: '5px' }} // Centered textarea with increased height
          placeholder="Enter your message here..." // Placeholder text
        />
      </div>
    </div>
    
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowProposalModal(false)}>
      Close
    </Button>
    <Button variant="primary" onClick={handleProposalSubmit(eventDetails,AuthData?.user_id)}>
      Send
    </Button>
  </Modal.Footer>
</Modal>





    </div>
    </>
    </div>
  );
};

export default EventListComponent;






